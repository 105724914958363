<template>
	<div class="sideItem" v-if="!item.hideInMenu">
		<template v-if="hasOneShowingChild(item.children,item)">
			<el-menu-item :index="item.path" @click="goTo(item)">
				<i :class="['iconfont', 'icon', item.icon]"></i>
				<span>{{ item.title }}</span>
			</el-menu-item>
		</template>

		<el-submenu v-else :index="item.path">
			<template slot="title">
				<i :class="['iconfont', 'icon', item.icon]"></i>
				<span>{{ item.title }}</span>
			</template>
			<el-menu-item v-for="(child, index) in item.children.filter(i => !i.hideInMenu)" :key="index" :index="child.path" @click="goTo(child)">
				<span slot="title">{{ child.title }}</span>
			</el-menu-item>
		</el-submenu>
<!--		<el-menu-item v-else :index="item.path" @click="goTo(item)">
			<i :class="['iconfont', 'icon', item.icon]"></i>
			<span>{{ item.title }}</span>
		</el-menu-item>-->
	</div>
</template>

<script>
	export default {
		// 菜单项
		name: 'sideItem',
		props: {
			item: {
				type: Object,
				required: true,
			},
		},
		mounted() {},
		methods: {
			hasOneShowingChild(children = [], parent) {
				console.log(parent,'paraent')
				const showingChildren = children.filter(item => {
					if (item.hideInMenu) {
						return false
					} else {
						return true
					}
				})
				if (showingChildren.length === 0) {
					return true
				}

				return false
			},
			goTo(item) {
				if (this.$route.name !== item.name) {
					this.$router.push({path: `${item.path}?${item.scenarioId}`})
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	::v-deep {
		.el-menu-item.is-active {
			background: #3fcf89 !important;
			color: #fff;
			width: 206px;
		}
		.el-submenu__title {
			width: 200px;
		}
		.el-submenu .el-menu-item {
			padding-left: 48px !important;
		}
	}
	.icon {
		padding-right: 8px;
		color: #fff;
		font-size: 20px;
	}
</style>
