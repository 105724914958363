import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permisson'
// 公共样式
import '@/styles/common.scss'
// 图标库引用
import '@/assets/font/iconfont.css'
// UI组件
import { Message } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'
Vue.prototype.$message = Message
Vue.use(ElementUI)

//查看大图
import ImgView from '@/components/zoomIn/zoomIn.js'
Vue.use(ImgView)
// 图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import '@/utils/echarts-tooltip'
// highcharts 引用
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)

// 表格头部
const headerCellStyle = {
    color: '#333',
    fontWeight: 500,
    background: '#F4F4F4',
    width: '100%'
}
Vue.prototype.headerCellStyle = headerCellStyle

// 全局组件呢
import ImgaeDialog from './components/imageDialog.vue'
Vue.component('ImgaeDialog', ImgaeDialog)
import IsEmpty from './components/isEmpty.vue'
Vue.component('IsEmpty', IsEmpty)

// 高德地图
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
    key: '30e92498872f5caf82e037941f476a97',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Geocoder',
        'AMap.Geolocation'
    ],
    v: '1.4.4',
    uiVersion: '1.0'
})
import AMapLoader from '@amap/amap-jsapi-loader'
Vue.use(AMapLoader)

import validate from '@/utils/validateDate'
Vue.prototype.validate = validate

Vue.config.productionTip = false

// 防抖处理
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
    let timer
    let newFunc = func
    if (event === 'click') {
        newFunc = function () {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, arguments)
            }, 500)
        }
    }
    on.call(this, event, newFunc)
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
