<template>
	<el-dialog title="查看图片" :visible.sync="imageVisible" :close-on-click-modal="false" width="800px" append-to-body :before-close="handleClose">
		<el-carousel :autoplay="false" arrow="always" height="500px">
			<el-carousel-item v-for="(item, index) in imagesList" :key="index">
				<img style="height: 100%; width: 100%; object-fit: contain" :src="item.url" alt="" />
			</el-carousel-item>
		</el-carousel>
		<div slot="footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleClose">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	// 查看图片公共弹窗
	name: 'imgaeDialog',
	props: {
		imageVisible: {
			type: Boolean,
			default: false,
		},
		imagesList: {
			type: Array,
			default: () => {
				return [
					{ url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
					{ url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg' },
					{ url: 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg' },
				]
			},
		},
	},
	methods: {
		handleClose() {
			this.$emit('update:imageVisible', false)
		},
	},
}
</script>

<style lang="scss" scoped>
.myImg {
	width: 100%;
	object-fit: none;
}
</style>
