// 需要跳过统一处理code的接口列表
export const SPECIAL_URL_LIST = [
    '/statisticalReport/exportElderlyStatistics',
    '/statisticalReport/exportServiceSubsidyStatic',
    '/archivesRegistrationAuditRecord/export',
    '/assessEvaluationProject/projectExport',
    '/assessEvaluationProject/objectImport',
    '/assessEvaluationProject/report/export',
    '/advancedAgeSubsidy/elderly/export',
    '/advancedAgeSubsidy/elderly/template/export',
    '/advancedAgeSubsidy/reportList/export',
    '/advancedAgeSubsidy/statistics/export',
    '/equipmentOrder/web/pageDown',
    '/managerDevice/bindDeviceListDown',
    '/pensionRiskEvent/riskEventListPageDown'
]
