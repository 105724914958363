<template>
    <div class="isEmpty">
        <img src="../assets/imgs/empty.png" alt="" />
        <span>暂无数据</span>
    </div>
</template>

<script>
export default {
    name: 'isEmpty'
}
</script>

<style lang="scss" scoped>
.isEmpty {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 0.885417rem 0 /* 170/192 */;
    img {
        width: 0.520833rem /* 100/192 */;
    }
    span {
        color: #666;
        font-size: 0.072917rem /* 14/192 */;
        font-weight: 400;
    }
}
</style>
