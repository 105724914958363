<template>
	<el-dialog title="修改密码" :visible.sync="dialogVisible" :close-on-click-modal="false" width="846px" :before-close="handleClose">
		<el-form class="myForm" :model="passwordForm" :rules="rules" ref="passwordForm" label-width="100px">
			<el-form-item label="用户名" prop="userName">
				<el-input v-model="userName" readonly :disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="旧密码" prop="oldPassword">
				<el-input v-model="passwordForm.oldPassword"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input v-model="passwordForm.newPassword"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="passwordConfirm">
				<el-input v-model="passwordForm.passwordConfirm"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="doSubmit">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { changePassword } from '@/api/common'
export default {
	// 更改密码
	name: 'changePassword',
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		userName: () => {
			const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
			return userInfo?.username || '--'
		},
	},
	data() {
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.passwordForm.newPassword) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			passwordForm: {
				oldPassword: '',
				newPassword: '',
				passwordConfirm: '',
			},
			rules: {
				oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
				newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
				passwordConfirm: [{ required: true, validator: validatePass2, trigger: 'blur' }],
			},
		}
	},
	methods: {
		handleClose() {
			this.$refs.passwordForm.resetFields()
			this.$emit('update:dialogVisible', false)
		},
		doSubmit() {
			this.$refs.passwordForm.validate((valid) => {
				if (valid) {
					this.$confirm('此操作将修改登录密码，成功后将重新登录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					})
						.then(() => {
							changePassword({
								userName: this.userName,
								newPassword: this.passwordForm.newPassword,
								oldPassword: this.passwordForm.oldPassword,
							}).then((res) => {
								this.$message.success(res.message)
								localStorage.clear()
								this.$router.push({ path: '/login' })
							})
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '已取消修改密码！',
							})
						})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.myForm {
	width: 80%;
	margin: 0 auto;
}
</style>
