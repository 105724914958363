import zoomIn from "./zoomIn.vue"
export default {
    install(vue) {
        const Constructor = vue.extend(zoomIn) //将组件作为参数传入 创建新的构造器
        vue.prototype.$zoomIn = urlList => { //将zoomIn 挂在到原型对象
            console.log(urlList,'????')
            let imgs = []
            if(urlList && urlList.length) {
                urlList.forEach((item) => {
                    if(item.key) {
                        imgs.push(item.value)
                    }else {
                        imgs.push(item)
                    }

                })
            }else {
                imgs = []
            }

            const instance = new Constructor({ //构造构造器实例
                data() {
                    return {
                        urlList: imgs
                    }
                }
            })
            const instanceDom = instance.$mount() //将构造的模板渲染到文档之外
            document.body.appendChild(instanceDom.$el) //将元素挂在到body上
        }
    }
}
