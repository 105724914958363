<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import './utils/flexible.js'
export default {
	name: 'App',
}
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	font-size: 0.083333rem /* 16/192 */;
}
</style>
