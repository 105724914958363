import { staticRoutes } from '@/router'
import { getMenuTree, setUpMenuTree } from "@/api/common.js"
// asyncRoutes
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role)) //找到满足这个角色的路由菜单返回 只要找到一个 就返回 true
  } else {
    return true //如果没有角色权限 就直接返回true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) { //如果找到一个有角色权限的或者没有一个角色
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes //权限路由
    state.routes = staticRoutes.concat(routes) //公共路由+权限路由
  }
}

const actions = {
  generateRoutes({ commit }) { //这个角色是请求用户信息回来之后的角色
    return new Promise(resolve => {
      let accessedRoutes
      let userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
      if(userInfo.isPrimaryAccount === 1) {
        getMenuTree({userName:userInfo.username}).then(res => {
          accessedRoutes = res.data
          changeName(res.data)
          let arr = []
          res.data.forEach(item => {
            item.children.forEach(i => {
              arr.push(i)
            })
          })
          localStorage.setItem('mylist', JSON.stringify(res.data[0].children))
          localStorage.setItem('scenarioList', JSON.stringify(res.data))
          commit('SET_ROUTES', accessedRoutes)
          resolve(accessedRoutes)
        })
      }else {
        let params = {
          isPrimaryAccount: userInfo.isPrimaryAccount,
          userName: userInfo.username,
          roleId: '',
          unitId: userInfo.unitId
        }
        setUpMenuTree(params).then(res => {
          accessedRoutes = res.data
          changeName(res.data)
          let arr = []
          res.data.forEach(item => {
            item.children.forEach(i => {
              arr.push(i)
            })
          })
          localStorage.setItem('mylist', JSON.stringify(res.data[0].children))
          localStorage.setItem('scenarioList', JSON.stringify(res.data))
          commit('SET_ROUTES', accessedRoutes)
          resolve(accessedRoutes)
        })
      }


      // console.log(accessedRoutes,'?@@@@')
      // accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // if (roles.includes('admin')) { //如果包含了admin角色，就把所有路由直接赋值给accessedRoutes //如果账号是admin 就能看到所有路由页面
      //   accessedRoutes = asyncRoutes || []
      // } else {
      //   // 如果没有admin权限 就调用方法筛选包含其他角色权限的路由
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // }

    })
  }
}

function changeName(list) { //解决重复name的问题
  let fn = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i]
      item.name = item.name + item.scenarioId
        item.activeIndex = item.hideInMenu ? `/${item.path.split('/')[1]}` : item.path
      if(item.children && item.children.length) {
        fn(item.children)
      }
    }
  }
  fn(list)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
